<app-loader [isActive]="isLoading.value$ | async" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 matDialogTitle>
  <div class="flex flex-row items-center justify-start gap-2">
    <div *ngIf="selectedMode == 'default'">{{ 'comego.create' | translate }}</div>
    <div *ngIf="selectedMode == 'absence'">{{ 'comego.createAbsence' | translate }}</div>
    <div class="flex-auto"></div>
    <button mat-icon-button matDialogClose tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>
<mat-dialog-content cdkTrapFocus>
  <nav class="mat-tab-nav-with-ink flex flex-row mb-4 --page-nav" mat-tab-nav-bar mat-tab-nav [disableRipple]="true">
    <a
      href="#"
      mat-tab-link
      [ngClass]="{ 'mat-tab-active': selectedMode === 'default' }"
      [active]="selectedMode === 'default'"
      (click.prevent)="selectedMode = 'default'"
    >
      {{ 'comego.create' | translate }}
    </a>
    <a
      href="#"
      mat-tab-link
      [ngClass]="{ 'mat-tab-active': selectedMode === 'absence' }"
      [active]="selectedMode === 'absence'"
      (click.prevent)="selectedMode = 'absence'"
    >
      {{ 'comego.createAbsence' | translate }}
    </a>
  </nav>
  @switch (selectedMode) {
    @case ('absence') {
      <ng-container *ngTemplateOutlet="multiAbsence"></ng-container>
    }
    @default {
      <ng-container *ngTemplateOutlet="defaultView"></ng-container>
    }
  }
  <ng-template #defaultView>
    <form
      (submit)="$event.preventDefault()"
      [formGroup]="this.group"
      class="mb-8 flex flex-col space-y-4"
      *ngIf="this.group as grp"
    >
      <div class="flex flex-row gap-2">
        <div class="flex flex-auto flex-col items-stretch justify-start gap-2 lg:wflex-[1_0_440px]">
          <ng-container *ngIf="{ shown: isFieldShown('user') } as ctrl">
            <div
              class="flex w-full flex-col"
              [ngClass]="{ 'cursor-pointer': group.controls.user.enabled }"
              (click)="$event.preventDefault(); group.controls.user.enabled && openUserPicker()"
              *ngIf="ctrl.shown && (workspace$canManageOthers.value$ | async)"
            >
              <mat-form-field class="form-control-interactive">
                <mat-label>{{ 'user.select' | translate }}</mat-label>
                <div *ngIf="groupValue.user as user" class="-mb-16px flex items-center space-x-1.5">
                  <app-user-avatar size="40px" class="rounded-full" [userId]="user.id"></app-user-avatar>
                  <div class="flex flex-auto flex-col leading-none">
                    <span>{{ user.name }}</span>
                    <span class="mat-hint text-sm">{{ user.email }}</span>
                  </div>
                  <button
                    *ngIf="postDiffUser$ | async"
                    mat-icon-button
                    (click)="$event.preventDefault(); $event.stopPropagation(); patchValue({ user: null })"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
                <input
                  matInput
                  [value]="groupValue.user"
                  readonly
                  [disabled]="group.controls.user.disabled"
                  class="!pointer-events-none"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container *ngIf="{ shown: isFieldShown('date') } as ctrl">
            <div
              class="flex w-full flex-col"
              [ngClass]="{ 'cursor-pointer': group.controls.date.enabled }"
              (click)="$event.preventDefault(); group.controls.date.enabled && openCalPicker()"
              *ngIf="ctrl.shown"
            >
              <mat-form-field class="pointer-events-none">
                <mat-label>{{ 'timer.date' | translate }}</mat-label>
                <input
                  matInput
                  [value]="groupValue.date | formatDate"
                  [disabled]="group.controls.date.disabled"
                  class="!pointer-events-none"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container
            *ngIf="{
              error: getErrorObservable('name') | async,
              shown: isFieldShown('name'),
            } as ctrl"
          >
            <mat-form-field *ngIf="ctrl.shown">
              <mat-label>{{ 'Description' | translate }}</mat-label>
              <textarea type="text" matInput formControlName="name" cdkInitialFocus cols="3"></textarea>
              <mat-error *ngIf="ctrl.error as error">{{
                error.content | translate: (error.args | translateArgs)
              }}</mat-error>
              <mat-hint class="w-full text-right"> {{ groupValue.name?.length ?? 0 }} / {{ maxLength }} </mat-hint>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
      <div class="mat-bd-5 -mx-4 mb-8 flex flex-col space-y-4 rounded-lg px-4 pb-4 pt-3">
        <div class="mb-4 flex">
          <div class="flex flex-grow flex-col">
            <div class="flex flex-col">
              <ng-container *ngIf="duration$ | async as dur">
                <div class="flex flex-col space-y-0.5">
                  <span class="text-muted mat-typography"
                    >{{ 'comego.types.work' | translate }}:
                    {{ dur.working + dur.absence | parseMsAsDuration: false }}
                  </span>
                  <span class="text-muted mat-typography"
                    >{{ 'comego.types.pause_short' | translate }}:
                    {{ dur.pause | parseMsAsDuration: false }}
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="flex-shrink-0">
            <ng-container *ngIf="currentDateHasSchedule$ | async">
              <button mat-stroked-button type="button" (click)="resetToSchedule()">
                {{ 'schedules.resetToUserSchedule' | translate }}
              </button>
            </ng-container>
          </div>
        </div>
        <div class="flex flex-col space-y-3" *ngIf="{ times: getErrorObservable('times') | async } as error">
          <ng-container
            *ngFor="let comegoGroup of group.controls.times.controls; let ctrlIndex = index; trackBy: ctrlIndex"
          >
            <div class="flex flex-col" ngClass.lt-md="mat-bg-5 rounded-lg px-4 pt-3 pb-4">
              <form
                ngClass.gt-sm="group flex space-x-2 flex-grow"
                ngClass.lt-md="group flex flex-col space-y-1 space-x-0"
                (submit)="$event.preventDefault()"
                [formGroup]="comegoGroup"
              >
                <div class="flex flex-grow space-x-2" *ngIf="{ enabled: enabledTypes.value$ | async } as ctrl">
                  <div
                    class="mt-4 flex size-6 flex-shrink-0 items-center justify-center rounded-full bg-white p-1 leading-6 shadow dark:bg-neutral-600 dark:shadow-none"
                    [ngClass]="{
                      'opacity-60': comegoGroup.controls.type.disabled,
                    }"
                    *ngIf="iconMap[comegoGroup.getRawValue()?.type] as type"
                  >
                    <mat-icon inline class="text-center">{{ type }}</mat-icon>
                  </div>
                  <mat-form-field class="flex wflex-[1_0_140px]" ngClass.lt-md="flex-1">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type">
                      <mat-option [disabled]="!ctrl.enabled.work" value="work">{{
                        'comego.types.work' | translate
                      }}</mat-option>
                      <mat-option [disabled]="!ctrl.enabled.pause" value="pause">{{
                        'comego.types.pause' | translate
                      }}</mat-option>
                      <ng-container *ngIf="!absenceSubcategories || absenceSubcategories.length === 0">
                        <mat-option [disabled]="!ctrl.enabled.absence" value="absence">
                          {{ 'comego.types.absence' | translate }}
                        </mat-option>
                      </ng-container>
                      <ng-container *ngIf="absenceSubcategories && absenceSubcategories.length > 0">
                        <mat-optgroup label="{{ 'Abwesenheiten' }}" [disabled]="!ctrl.enabled.absence">
                          <mat-option *ngFor="let subcategory of absenceSubcategories" [value]="subcategory.id">
                            {{ subcategory.title }}
                          </mat-option>
                        </mat-optgroup>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="flex space-x-2">
                  <div
                    ngClass.gt-sm="grid grid-cols-[90px_1fr_90px] items-center space-x-2"
                    ngClass.lt-md="grid grid-cols-[1fr_40px_1fr] items-center"
                  >
                    <app-time-input
                      matInput
                      [inputPlaceholder]="'timer.time.start' | translate"
                      formControlName="start"
                      [displayFormat]="false"
                      #tStartFormField
                      [maxlength]="5"
                      elementRef
                      #startInpRef="nativeElement"
                      (focus)="this.selectInput($event, startInpRef)"
                      (keydown.enter)="$event.preventDefault()"
                      (click)="$event.stopPropagation(); this.selectInput($event, startInpRef)"
                    >
                      <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
                        <mat-form-field fxFill>
                          <mat-label>{{ inputPlaceholder }}</mat-label>
                          <input type="text" matInput [placeholder]="inputPlaceholder" [formControl]="control" />
                        </mat-form-field>
                      </ng-template>
                    </app-time-input>
                    <span class="tg-seperator mx-2 justify-self-center">
                      <mat-icon>arrow_forward</mat-icon>
                    </span>
                    <app-time-input
                      matInput
                      [inputPlaceholder]="'timer.time.end' | translate"
                      formControlName="end"
                      [displayFormat]="false"
                      #tEndFormField
                      [maxlength]="5"
                      elementRef
                      #endInpRef="nativeElement"
                      (focus)="this.selectInput($event, endInpRef)"
                      (keydown.enter)="$event.preventDefault()"
                      (click)="$event.stopPropagation(); this.selectInput($event, endInpRef)"
                    >
                      <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
                        <mat-form-field fxFill>
                          <mat-label>{{ inputPlaceholder }}</mat-label>
                          <input type="text" matInput [placeholder]="inputPlaceholder" [formControl]="control" />
                          <mat-hint *ngIf="calculateDuration(comegoGroup, ctrlIndex) as duration">
                            {{ duration | parseMsAsDuration: false }}
                          </mat-hint>
                        </mat-form-field>
                      </ng-template>
                    </app-time-input>
                  </div>
                  <div class="mt-2 flex space-x-2 opacity-40 group-hover:opacity-100">
                    <button
                      mat-icon-button
                      [disabled]="comegoGroup.disabled || (this.data?.time?.lockItem && ctrlIndex === 0)"
                      (click)="deleteItem(ctrlIndex)"
                    >
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </div>
              </form>
              <div class="text-warn -mt-2 ml-8 text-sm" *ngIf="error.times?.index === ctrlIndex">
                {{ error.times.content | translate: (error.times.args || {} | translateArgs) }}
              </div>
            </div>
          </ng-container>
          <div class="flex justify-center">
            <button
              mat-icon-button
              color="primary"
              (click)="addTime()"
              [disabled]="group.disabled || !(canAddTime.value$ | async)"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-container
    *ngIf="{
      times: getErrorObservable('times') | async,
      manual: error$.value$ | async,
    } as error"
  >
    <div class="pb-16" *ngIf="error.manual || error.times"></div>
    <div class="absolute inset-x-0 bottom-0 mx-3 mb-14">
      <div class="alert alert-danger alert-tight rounded py-2" *ngIf="error.manual as err">
        {{ err.message | translate: (err.args | translateArgs) }}
      </div>
    </div>
    <ng-container *ngIf="!error.manual && error.times && error.times.index === undefined && error.times as br">
      <div class="absolute inset-x-0 bottom-0 mx-3 mb-14">
        <div class="alert alert-danger alert-tight rounded py-2">
          {{ br.content | translate: (br.args || {} | translateArgs) }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled">
  <button
    mat-flat-button
    class="mat-action-filled"
    color="primary"
    (click)="submit()"
    [disabled]="group.invalid || (error$.value$ | async)"
  >
    {{ selectedMode === 'default' ? ('comego.create' | translate) : ('comego.createAbsence' | translate) }}
  </button>
</mat-dialog-actions>
<ng-template #multiAbsence>
  <form
    (submit)="$event.preventDefault()"
    [formGroup]="this.absenceGroup"
    class="mb-8 flex flex-col space-y-4"
    *ngIf="this.absenceGroup as grp"
  >
    <div class="flex flex-row gap-2">
      <div class="flex flex-auto flex-col items-stretch justify-start gap-2 lg:wflex-[1_0_440px] pt-2">
        <ng-container *ngIf="workspace$canManageOthers.value$ | async">
          <button
            class="mat-button-alt"
            [satPopoverAnchor]="employeeFilterPopover"
            (click)="employeeFilterPopover.toggle()"
            [class.active]="1 == 1"
          >
            <div class="flex flex-row items-center justify-start gap-1">
              <mat-icon inline>supervisor_account</mat-icon>
              <span>{{ 'reports.filter-bar.users' | translate }}</span>
            </div>
            <ng-container *ngIf="employeeFilter$ | async as c">
              <span class="filter-badge" *ngIf="c.length > 0">{{ c.length }}</span>
            </ng-container>
          </button>
        </ng-container>
        <app-time-range-picker
          *ngIf="(employeeFilter$ | async)?.length > 0"
          class="mt-4 justify-center sm:items-start sm:justify-start"
          [(range)]="this.range"
          (rangeChange)="this.setRange($event)"
          [disabled]="false"
        ></app-time-range-picker>
      </div>
    </div>
    <div
      class="mat-bd-5 -mx-4 mb-8 flex flex-col space-y-4 rounded-lg px-4 pb-4 pt-3"
      [satPopoverAnchor]="assumeAbsencePopover"
    >
      <div class="flex flex-col space-y-3" *ngIf="{ times: getErrorObservable('times') | async } as error">
        <ng-container formArrayName="employees">
          <div *ngFor="let empGroup of grp.get('employees').controls; let i = index" [formGroupName]="i">
            <div class="mb-3 flex items-center">
              <app-user-avatar
                size="40px"
                class="rounded-full"
                [userId]="empGroup.get('employee').value"
              ></app-user-avatar>
              <div [style.marginLeft]="'8px'">
                {{ getUserById(empGroup.get('employee').value)?.name }}
              </div>
            </div>

            <ng-container formArrayName="absences">
              <div *ngFor="let absence of empGroup.get('absences').controls; let j = index" [formGroupName]="j">
                <div class="flex space-x-2">
                  <div>{{ absence.get('date').value | date: 'EEE' }}</div>
                  <div class="px-2">{{ absence.get('date').value | date: 'dd.MM.yyyy' }}</div>

                  <mat-form-field
                    class="flex wflex-[1_0_140px] px-2"
                    ngClass.lt-md="flex-1"
                    [style.marginTop]="'-17px'"
                  >
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type" (selectionChange)="onAbsenceSubcategorySelectionChange($event)">
                      @if (!absenceSubcategories?.length) {
                        <mat-option value="absence">
                          {{ 'comego.types.absence' | translate }}
                        </mat-option>
                      } @else {
                        <mat-optgroup [label]="'comego.types.absence' | translate">
                          <mat-option *ngFor="let subcategory of absenceSubcategories" [value]="subcategory">
                            {{ subcategory.title }}
                          </mat-option>
                        </mat-optgroup>
                      }
                    </mat-select>
                  </mat-form-field>
                  <div class="px-2">
                    {{ getScheduleForUser(empGroup.get('employee').value, absence.get('date').value) }}
                  </div>
                  <app-time-input
                    matInput
                    formControlName="hours"
                    [displayFormat]="false"
                    #tStartFormField
                    [maxlength]="5"
                    elementRef
                    #startInpRef="nativeElement"
                    (focus)="this.selectInput($event, startInpRef)"
                    (keydown.enter)="$event.preventDefault()"
                    (click)="$event.stopPropagation(); this.selectInput($event, startInpRef)"
                    class="w-[86px] pl-2"
                    [style.marginTop]="'-17px'"
                  >
                    <ng-template timeInputControl let-control="control">
                      <mat-form-field fxFill>
                        <input type="text" matInput [placeholder]="inputPlaceholder" [formControl]="control" />
                      </mat-form-field>
                    </ng-template>
                  </app-time-input>
                  <div
                    *ngIf="workspace$canManageOthers.value$ | async"
                    class="mt-2 flex space-x-2 opacity-40 group-hover:opacity-100"
                    [style.marginTop]="'-8px'"
                  >
                    <button mat-icon-button (click)="disableAbsence(i, j)">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</ng-template>
<tg-employee-filter-popover
  #employeeFilterPopover
  (selectedItemsChange)="employeeFilter = $event"
  [strictMode]="true"
></tg-employee-filter-popover>
<sat-popover #assumeAbsencePopover hasBackdrop>
  <mat-card class="mat-elevation-z2 !rounded-lg !px-0 !pb-4 !pt-2">
    <mat-card-content class="flex flex-col px-2 pt-2 !bg-transparent">
      <div matDialogTitle class="mat-dialog-title-base flex flex-col">
        <h4 class="my-0">Auswahl auf alle Einträge anwenden?</h4>
        <small class="mat-hint">{{ initialAbsenceSubcategorySelection?.title }}</small>
      </div>
      <mat-dialog-content> </mat-dialog-content>
      <mat-dialog-actions class="flex flex-row items-center justify-between pb-4">
        <button mat-stroked-button (click)="applyAbsenceSubcategorySelection()">
          {{ 'utils.apply' | translate }}
        </button>
        <button mat-stroked-button (click)="assumeAbsencePopover.close()">
          {{ 'utils.cancel' | translate }}
        </button>
      </mat-dialog-actions>
    </mat-card-content>
  </mat-card>
</sat-popover>
