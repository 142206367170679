import { endOfDay, isValid, startOfDay } from 'date-fns/esm';

export function clampDay(currentDate: Date) {
  return (val: Date) => {
    if (val.getDate() > currentDate.getDate()) {
      return endOfDay(currentDate);
    } else if (val.getDate() < currentDate.getDate()) {
      return startOfDay(currentDate);
    }
    return val;
  };
}
export function removeOffsetFromDate(date: Date) {
  const offset = new Date().getTimezoneOffset() * -1 * 60 * 1000;
  return new Date(date.getTime() + offset);
}

/**
 * isValid replacement for date-fns
 * added null check, invalidate 1970 year
 * @param date date object
 * @returns
 */
export function isValidDate(date: any) {
  return date && isValid(date) && (date as Date).getFullYear() !== 1970;
}
